<template>
    <div class="list widget">
        <h1 class="title">
            {{ title }}
        </h1>
        <div :class="['widget-container', isGroup ? 'group_list' : '']">
            <!-- <h2 class="sub-title" @click="onTitlePressed">
                {{ subTitle }}
            </h2> -->
            <div class="sub-title-container" @click="onTitlePressed">
                <h2 class="sub-title">
                    {{ subTitle }}
                </h2>
                <div class="back-button">
                    <img src="@assets/img/icons/ic-arrow-foward.svg">
                </div>
            </div>
            <slot name="list" />
        </div>
    </div>
</template>

<script>
export default {
    name: "ListWidget",
    props: {
        title: {
            type: String,
            default: ""
        },
        subTitle: {
            type: String,
            required: true
        },
        entity: {
            type: Object,
            required: true
        },
        isGroup: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onTitlePressed() {
            return this.$router.push({
                name: "web-app-notable-details",
                params: {
                    uuid: this.entity.widget_reference,
                    type: this.entity.category.name,
                    title: this.title,
                    subtitle: this.subTitle
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.list {
    .group_list {
        height: auto !important;
    }

    .title {
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 15px;
        margin-top: 30px;
    }

    .widget-container {
        width: 350px;
        background-color: #1C1C1E;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: hidden;
        padding: 12px;
        height: 362px;

        .sub-title {
            font-weight: normal;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 25px;
            margin-top: 10px;
            cursor: pointer;
        }

        .people-card {
            width: initial;
            padding: 0;

            /deep/ .people-header {
                .follow-button {
                    .btn {
                        width: 88px;
                    }
                }
            }
        }

        .people-card:last-child {
            margin-bottom: 0;
        }

        .topic-slot:last-child {
            margin-bottom: 0;
        }

        .sub-title-container {
            display: flex;
            justify-content: space-between;
            .back-button {
                width: 40px;
                height: 40px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 40px;
                }
            }
        }
    }
}
</style>
